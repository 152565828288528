@font-face {
  font-family: 'CoStar Brown Regular';
  src: url('./lineto-costar-brown-regular.woff') format('woff'),
    url('./lineto-costar-brown-regular.svg') format('svg');
}


@font-face {
  font-family: 'CoStar Brown Light';
  src: url("./lineto-costar-brown-light.eot");
  src: url("./lineto-costar-brown-light.eot?#iefix") format("embedded-opentype"), url("./lineto-costar-brown-light.woff") format("woff"), url("./lineto-costar-brown-light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Add show for Chip input selection */
@media (max-width: 1010px) {
  .leftside-content .market-chipinput {
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
  }

  .select-markets {
    display: none;
  }

  .map-view {
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: 100%;
  }
}

/* @media (max-width: 320px) { */
@media (max-width: 660px) {
  html {
    height: 100%;
  }

  body {
    height: 100%;
    overflow: hidden;
  }

  .header-top {
    flex-direction: column;
    max-height: 180px;
  }

  .header-top .header-component-heading {
    min-width: 320px;

    max-height: 60px;

    text-align: center;
    text-indent: 0px;

    font-size: 18px;
  }

  .main-component .bottom-component {
    overflow: scroll;
    min-height: 100vh;
  }

  .bottom-component .leftside-component {
    min-width: 320px;

    flex-basis: 660px;

    overflow: scroll;
  }

  .header-top .toggle-buttons {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

    width: 208px;
  }

  .header-top .header-spacing {
    margin-right: 30px;

    max-height: 60px;

    position: fixed;
    z-index: 8;

    width: 100%;
  }

  .header-spacing button {
    box-shadow: 3px 3px 6px #AAA;
  }

  /* div containing the save button */
  .header-spacing>div {
    width: 75px;
  }

  .save-button-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .save-button-wrapper button {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  .rightside-component {
    display: none;
  }
}

.mobile-device .leftside-content .market-chipinput {
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
}

.mobile-device .select-markets {
  display: none;
}


button:focus {
  outline: none;
}

.market-chipinput {
  display: none;
}

.main-component {
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  font-family: 'CoStar Brown Light';
}

.no-flicker .no-flickering {
  display: none;
}

.display-error {
  margin-top: 50px;
  text-align: center;
  font-size: 30px;
}

.spinner {
  margin-top: 280px;

  width: 128px;
  height: 128px;

  margin-left: auto;
  margin-right: auto;

  background-image: url("./loadingSM.gif");
  background-position: center;
  background-repeat: no-repeat;
}

.toast-bg {
  width: 100%;
  height: 100%;

  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;

  background-color: rgba(255, 255, 255, 0.5);
}

.toast {
  margin-top: 310px;
  margin-left: auto;
  margin-right: auto;
  width: 280px;
  height: 140px;
  background-color: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
}

.toast-body {
  padding: 24px;
  height: 56px;
}

.toast-body>div {
  float: left;
}

.toast-icon {
  margin-top: 13px;

  width: 28px;
  height: 28px;

  background-image: url('./toastCheck.png');
}

.toast-text {
  margin-left: 12px;
  width: 190px;

  font-size: 21px;
  color: #103060;
}

.header-top {
  display: flex;
  min-height: 60px;
  background-color: #FFF;
  border-top: 2px solid darkgray
}

.header-component-heading {
  flex-basis: 694px;
  min-width: 368px;
  line-height: 60px;
  font-size: 24px;
  font-weight: 300;
  text-indent: 8px;
  color: #103060;
}

.header-shadow {
  height: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
}

.header-spacing {
  flex-basis: 694px;
}

.header-spacing button {
  margin-top: 20px;
  box-sizing: border-box;
  height: 40px;
  width: 120px;
  border: 1px solid #0559B3;
  border-radius: 3px;
  background-color: #0559B3;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0.04px;
  color: #FFFFFF;
  text-align: center;
}

.header-spacing button:hover {
  cursor: pointer;
}

.header-spacing button.disabled {
  background-color: #BFBFBF;
  border: 1px solid #BFBFBF;
}

.header-spacing button.disabled:hover {
  cursor: default;
}

.header-spacing>div {
  margin-right: 20px;

  float: right;
}

.bottom-component {
  /*display: flex;*/
  height: 400px;
}

.leftside-component {
  flex-basis: 368px;
  min-width: 368px;
  height: 100%;
}

.leftside-content {
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: 11px;
  padding-right: 18px;
  font-size: 18px;
  font-weight: 300;
}

.property-location,
.space-use,
.property-type {
  height: 24px;
  line-height: 24px;
}

.space-use,
.property-type {
  margin-bottom: 8px;
}

.rightside-component {
  flex-basis: 1232px;

  height: 798px;

  background-image: url('./subscriptionBackground.jpeg');
  background-repeat: no-repeat;
  position: relative;
}

.rightside-component.no-bg {
  background-image: none;
}

.hint {
  color: #d0021b;
  font-size: 14px;
  font-weight: 300;
  white-space: nowrap;
  display: block;
  z-index: 10;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px
}

.btn-primary-blue {
  color: #fff;
  background-color: #0559b3;
  border: #0559b3;
  border-radius: 4px;
  box-shadow: none;
  font-size: 20px;
}

.toggle-buttons {
  min-width: 208px;

  margin-top: 10px;
  margin: 0 auto;
}

.toggle-buttons button {
  background-color: #FFFFFF;
  border-radius: 3px;
  width: 104px;
  height: 39px;
  font-size: 20px;
  color: #508AC9;


  border: 1px solid #979797;
}

.toggle-buttons .disabled {
  background-color: #EDECEC;
  color: #9B9B9B;
  box-shadow: none;
}

.toggle-buttons .disabled:hover {
  cursor: pointer;
}

.select-markets {
  margin-top: 10px;
  margin-bottom: 30px;

  height: 26px;

  color: #0559B3;
}

.select-markets>div:first-child {
  height: 26px;
  line-height: 26px;
  cursor: pointer;
}

.markets-selected {
  margin-left: 10px;

  font-size: 15px;
  color: #0559B3;
  height: 26px;
  line-height: 28px;

  cursor: pointer;
}

.select-markets div {
  float: left;
}

.pencil-icon {
  margin-top: 7px;
  margin-left: 4px;

  width: 13px;
  height: 13px;
  background-image: url("./pencilSM.png");
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox-wrapper {
  margin-top: 25px;
}

.space-range .checkbox-wrapper {
  margin-top: 0px;
}

.checkbox-wrapper+.checkbox-wrapper {
  margin-top: 0px;
}

.checkbox-container {
  margin-top: 25px;
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: -0.2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #FFF;
  border-radius: 3px;
  border: 1px solid #C5C5C5;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: #0559B3;
  border: 1px solid #0559B3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* market css start here */
.market-modal {
  position: absolute;
  background-color: white;
  display: block;
  height: 621px;
  width: 593px;
  border: 0.75px solid #CACACA;
  padding-top: 22px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 40px;
  box-shadow: 8px 1px 12px -7px rgba(0, 0, 0, 0.38);
  z-index: 1;
}

.market-modal.hide {
  display: none
}

.market {
  height: 100%;
  width: 100%;
}

.market .market-content {
  width: 100%;
  height: 100%;
}

.market .market-content .market-grid .market-col {
  float: left;
  width: 45%;
  height: 100%;
}

.market .market-content .market-grid .market-col-middle {
  float: left;
  width: 10%;
  height: 100%;
}


.market .market-content .market-grid .market-col .market-col-header {
  display: row;
}

.market .market-content .market-grid .button-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.button-wrapper button:hover {
  cursor: pointer;
}

.market .market-content .market-grid .market-spacer {
  height: 15px;
}

.market .market-content .market-grid {
  clear: both;
  width: 100%;
  height: 100%;

}

.market .market-content .market-grid .market-col-container-table .title {
  width: 100%;
  display: table-row;
  height: 17px;
  color: #404040;
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0.28px;
  line-height: 17px;
}

.market .market-content .market-grid .market-col-container-table .search {
  display: table-row;
  width: 100%;
}


.market .market-content .market-grid .market-col-container-table .search .search-input {
  height: 30px;
  width: 100%;
  border: 1px solid #999999;
  border-radius: 2px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
}

.market .market-content .market-grid .market-col-container-table .search .search-input input[type=text] {
  height: 17px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.52px;
  line-height: 17px;
  flex: 1 1 200px;
  border: none;
  outline: none;
  padding-left: 10px;
}

.market .market-content .market-grid .market-col-container-table .search .search-input .search-icon {
  flex: 0 0 20px;
  background-image: url("./search.png");
  height: 15px;
  width: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}




.market .market-content .market-grid .market-col-container-table .add-clear {
  width: 100%;
  display: flex;
}

.market .market-content .market-grid .market-col-container-table .add-clear .add-clear-col {
  width: 50%;
}

.market .market-content .market-grid .market-col-container-table .add-clear .padding-left {
  padding-left: 5px;
}

.market .market-content .market-grid .market-col-container-table .add-clear .padding-right {
  padding-right: 5px;
}

.add-clear-col button:hover {
  cursor: pointer;
}

.market .market-content .market-grid .market-col-container-table .add-clear .add-clear-col .market-button-add-clear {
  width: 100%;
  height: 30px;
  border: 1px solid #1B3A6E;
  border-radius: 2px;
  background-color: #FFFFFF;
  color: #1B3A6E;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.32px;
  line-height: 17px;
}


.market .market-content .market-grid .market-col-container-table {
  display: table;
  height: 100%;
  width: 100%;
}

.market .market-content .market-grid .market-col-container-table .market-selector {
  width: 100%;
  height: 100%;
  display: table-row;
}

.market .market-content .market-grid .market-col-container-table .market-selector .market-selector-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.market .market-content .market-grid .market-col-container-table .market-selector .market-selector-container .market-selector-input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.52px;
  line-height: 17px;

  padding-top: 8px;
  padding-left: 8px;
  color: #404040;
  font-family: Helvetica;
}

.market .market-content .market-grid .market-col-container-table .market-selector .market-selector-container .market-selector-input option {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.52px;
  line-height: 17px;
  color: #404040;
  font-family: Helvetica;
}


.market .market-content .market-grid .market-col-container-table .market-selector .market-selector-container .market-selector-message {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}



.market .market-content .market-grid .market-col-container-table .market-selector .market-selector-container .market-selector-message span {
  font-family: Helvetica;
  margin-left: 32px;
  margin-right: 32px;
  color: #808080;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.52px;
  line-height: 17px;
}


.market .market-footer {
  line-height: 68px;
  width: 100%;
}


.market .market-button {
  display: block;
  height: 44px;
  width: 100%;
  border: 1px solid #C5C5C5;
  background-color: #F5F5F5;
  background-repeat: no-repeat;
  background-position: center;
}

.market .market-button.single-left {
  background-image: url("./market-arrow-left.png");
}

.market .market-button.double-left {
  background-image: url("./market-double-arrow-left.png");
}

.market .market-button.single-right {
  background-image: url("./market-arrow-right.png");
}

.market .market-button.double-right {
  background-image: url("./market-double-arrow-right.png");
}

.market .market-action-button {
  height: 29px;
  line-height: 26px;
  width: 80px;
  border: 1px solid #103060;
  border-radius: 2px;
  background-color: #FFFFFF;
  color: #103060;
  font-family: Helvetica;
  font-size: 14px;
  margin-left: 10px;
}

.market .market-action-button:hover {
  cursor: pointer;
}

.market .market-action-button.highlighted {
  background-color: #0559B3;
  color: #FFFFFF;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.costar-gmap-map-component {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.small-width {
  width: 80px;
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction 
    and if we allow the items to wrap 
  * Remember this is the same as:
  * flex-direction: row;
  * flex-wrap: wrap;
  */
  flex-flow: row wrap;

  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
}

.row.col-combo-2 {
  /* We first create a flex layout context */
  display: flex;
  flex-direction: row;
}

.error {
  border-color: red
}

/* set cursor style when polygons are hovered to the default of the map */
.gm-style:first-of-type>div:nth-child(1) {
  cursor: grab !important;
}

.col-xs-6.col-sm-6.col-md-6.col-lg-6.no-padding {
  margin-right: 10px
}

.star-rating-wrapper{
  margin-top:20px;
}
.suite-common-components-star-rating{
  margin-top:15px;
  margin-bottom:25px;
}

.suite-common-components-star-input{
  max-width:30px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.suite-common-components-star-input:hover, .suite-common-components-star-input:focus, .suite-common-components-star-input:active {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.suite-common-components-star-input path{
  fill: #0559B3;
}

::-ms-clear {
  display: none;
}

@media(min-width:660px) {
  .bottom-component .leftside-component .header-component .header-top {
    background-color: #f5f5f5;
    margin-top: 30px;
  }

  .bottom-component .leftside-component .leftside-content {
    padding-top: 10px;
  }
}

.tag-line {
  display: flex;
  background-color: #e5e5e5;
}

.tabs-nav {
  padding: 0px 20px 0;
}

.tabs-nav .tabs-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.listingManagerTitle {
  padding-right: 12px;
  margin-right: 23px;
  text-transform: capitalize;
  color: #183a6e;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: .23px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.masthead-center {
  justify-content: center;
  display: flex;
  height: 70px;
}

.masthead-logo {
  padding: 10px 10px 10px 10px;
}

img.masthead-logo.logo-ten-x {
  display: block;
  width: 150px;
}

.logo-separator {
  border-left: solid 2px  #bcbcbc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-costar {
  background-image: url("./logo-costar.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 200px;
}

.logo-loopnet {
  background-image: url("./logo-loopnet.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 200px;
}